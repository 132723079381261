<template>
  <div>

    <van-nav-bar :title="'访问数据统计'+'('+names+')'" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
        <div style="color: #0a873a;" v-if="vxMange == 1" @click="qianhuan">
          切换
        </div>
      </template>
    </van-nav-bar>
    <van-row class="biaotou">
      <van-col span="3">#</van-col>
      <van-col span="10">微信名称</van-col>
      <van-col span="5">头像</van-col>
      <van-col span="6">访问时间</van-col>
    </van-row>
    <div style="overflow: hidden;background: #fff;padding-top:46px">

      <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads" :offset="10">
        <van-row class="biaotou2" v-for="(item,idex) in list" :key="idex">
          <van-col span="3">{{idex+1}}</van-col>
          <van-col span="10">{{item.name}}</van-col>
          <van-col span="5" class="imgtx" style="">
            <img style="width: 24px;border-radius: 50%;" :src="item.avatar" alt="">
          </van-col>
          <van-col span="6">{{item.createdDate?item.createdDate.substr(5,11):''}}</van-col>
        </van-row>
      </van-list>
      <!-- <div class="he_shuju2">未核销的客户</div>
      <van-row class="biaotou">
        <van-col span="2">#</van-col>
        <van-col span="2">客</van-col>
        <van-col span="5">车牌号码</van-col>
        <van-col span="6">联系方式</van-col>
        <van-col span="6">购买时间</van-col>
        <van-col span="3">属性</van-col>
      </van-row>
      <van-row class="biaotou2" v-for="(item,idex) in list.unOffCustomerList" :key="idex">
        <van-col span="2">{{idex+1}}</van-col>
        <van-col span="2">
          <span v-if="item.newCar ==1" style="color: red;">新</span>
          <span v-else>&#12288;</span>
        </van-col>

        <van-col span="5">{{item.carNo}}</van-col>
        <van-col span="6">{{item.phone}}</van-col>
        <van-col span="6">{{item.bizDate?item.bizDate.substr(5,11):''}}</van-col>
        <van-col span="3" style="color: red;" v-if="item.agentBuy == 1" @click="todaiban(item)">代办</van-col>
        <van-col span="3" v-else>{{item.orderNumber}}</van-col>
      </van-row>
      <div class="he_shuju2">已核销的客户</div>
      <van-row class="biaotou">
        <van-col span="2">#</van-col>
        <van-col span="7">联系方式</van-col>
        <van-col span="5">详细信息</van-col>
        <van-col span="6">购买时间</van-col>
        <van-col span="4">属性</van-col>
      </van-row>
      <van-row class="biaotou2" v-for="(item,idexs) in list.offCustomerList" :key="idexs">
        <van-col span="2">{{idexs+1}}</van-col>
        <van-col span="7">{{item.phone}}</van-col>
        <van-col span="5">{{item.carNo}}</van-col>
        <van-col span="6">{{item.bizDate?item.bizDate.substr(5,11):''}}</van-col>
        <van-col span="4">{{item.orderNumber}}</van-col>
      </van-row> -->
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";

export default {
  data() {
    return {
      gid: "",
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      imgs: "../../assets/img/tongji.png",
      aid: this.$route.query.aid,
      uid: "",
      sjlist: [],
      listname: "",
      finished: false,
      loading: false,
      vxMange: sessionStorage.getItem("vxMange"),
      isoks: false,
      names: "个人",
      gids: "",
      list: [],
    };
  },
  methods: {},
  created() {
    //

    this.getlist();
  },
  methods: {
    onLoads() {
      this.page++;
      this.getlist();
    },
    qianhuan() {
      this.isoks = !this.isoks;
      if (this.isoks) {
        this.names = "全部";
      } else {
        this.names = "个人";
      }
      this.page = 0;
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.getlist();
    },
    getlist() {
      var data = {
        aid: this.aid,
        sid: this.isoks ? 0 : this.userInfo.userId,
        page: this.page,
        size: 50,
      };
      userModel.marketpage(data).then((res) => {
        if (res.code == 200) {
          let rows = res.data.content;
          this.loading = false;
          this.total = res.data.totalElements;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.xsytotal) {
            this.finished = true;
          }
        }
      });
    },

    xuanzhe(item) {
      console.log(item);
      item.isOk = !item.isOk;
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    toxinxi() {
      this.$router.push({
        path: "statistics",
        query: {
          aid: this.aid,
        },
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style  lang="less" scoped>
.che_h2 {
  width: 50%;
}
.heji_h1 {
  height: 25px;
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  background: #fff;
}
.hj_a {
  float: left;
  width: 33%;
  text-align: center;
}
.shenqin {
  border-top: 1px solid #f5f5f5;
  font-size: 14px;
  color: #666;
  line-height: 20px;
  padding-top: 8px;
}

.c_h1 {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 2px;
  width: 50%;
  height: 25px;
  margin-left: 55px;
}
.van-dropdown-menu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #fff;
}
.h1 {
  height: 45px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 99;
  line-height: 45px;
}
.van-dropdown-menu__title--active {
  color: #07c160;
}
.xuanzheqi {
  border: 1px solid #dcdfe6;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border-radius: 4px;
  width: 95%;
}

.mendianh {
  width: 30%;
  overflow: hidden;
}

.xiabiao {
  position: absolute;
  right: 0;
  top: 0;
  color: #666;
  margin-top: 15px;
  margin-right: 5px;
}
.topp {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.biaoti {
  padding: 10px 12px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
}

.red {
  color: red;
  font-weight: bold;
  z-index: 999;
}

.shuju {
  padding: 15px 12px;
  font-size: 14px;
}

.van-col {
  text-align: center;
  border-right: 1px solid #f1f1f1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 0 0px;
}
.imgs {
  width: 24px;
  margin-top: 11px;
}
.biaotou {
  border: 1px solid #f1f1f1;
  font-size: 14px;
  line-height: 30px;
  color: #000;
  position: sticky;
  top: 46px;
  width: 100%;
  background: #fff;
}

.biaotou2 {
  font-size: 14px;
  line-height: 35px;
  border: 1px solid #f1f1f1;
  border-top: none;
  color: #666;
}
.let {
  width: 40px;
  height: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.rit {
  width: 40px;
  height: 100%;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}
.he_shuju {
  margin-top: 15px;
  border-top: 1px solid #ccc;
  line-height: 40px;
  overflow: hidden;
  padding: 0 12px;
  font-size: 14px;
  font-weight: bold;
}

.he_shuju2 {
  margin-top: 15px;
  border-top: 1px solid #ccc;
  line-height: 40px;
  overflow: hidden;
  padding: 0 12px;
  font-size: 14px;
  text-align: center;
  color: #000;
  font-weight: bold;
}
.imgtx {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}
</style>
